<template>
	<div style="width: 100%;height: 100%;overflow: auto;">
		<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">

			<div class="mytable">
				<table>
					<thead>
						<tr class="tabHead">
							<th>{{$t('userTaskRecord[1]')}}</th>
							<th>{{$t('userTaskRecord[2]')}}</th>
							<th>{{$t('userTaskRecord[3]')}}</th>
							<th>{{$t('userTaskRecord[4]')}}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item,index) in backDate" :key="index">
							<td>{{item.rType}}</td>
							<td>{{item.rTime}}</td>
							<td>{{$t('userTaskRecord[5]')}}</td>
							<td>{{item.rGetCommission}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</van-list>
	</div>
</template>

<script>
	export default {
		created() {
			//创建组件时，加载第1页数据
			this.getroadList();
		},

		data() {
			return {
				isLoad: false,
				isFinished: false,
				isRefresh: false,
				pageNo: 1,
				popUpWindows: false,

				backDate: [{
						rType: 'youtubo',
						rTime: '2020-02-02',
						rCurrentState: '已完成',
						rGetCommission: '5'
					},
					{
						rType: 'youtubo',
						rTime: '2020-02-02',
						rCurrentState: '已完成',
						rGetCommission: '5'
					},
					{
						rType: 'youtubo',
						rTime: '2020-02-02',
						rCurrentState: '已完成',
						rGetCommission: '5'
					},
					{
						rType: 'youtubo',
						rTime: '2020-02-02',
						rCurrentState: '已完成',
						rGetCommission: '5'
					},
					{
						rType: 'youtubo',
						rTime: '2020-02-02',
						rCurrentState: '已完成',
						rGetCommission: '5'
					},
					{
						rType: 'youtubo',
						rTime: '2020-02-02',
						rCurrentState: '已完成',
						rGetCommission: '5'
					},
					{
						rType: 'youtubo',
						rTime: '2020-02-02',
						rCurrentState: '已完成',
						rGetCommission: '5'
					},
					{
						rType: 'youtubo',
						rTime: '2020-02-02',
						rCurrentState: '已完成',
						rGetCommission: '5'
					},
					{
						rType: 'youtubo',
						rTime: '2020-02-02',
						rCurrentState: '已完成',
						rGetCommission: '5'
					},
					{
						rType: 'youtubo',
						rTime: '2020-02-02',
						rCurrentState: '已完成',
						rGetCommission: '5'
					},
					{
						rType: 'youtubo',
						rTime: '2020-02-02',
						rCurrentState: '已完成',
						rGetCommission: '5'
					},
					{
						rType: 'youtubo',
						rTime: '2020-02-02',
						rCurrentState: '已完成',
						rGetCommission: '5'
					},
					{
						rType: 'youtubo',
						rTime: '2020-02-02',
						rCurrentState: '已完成',
						rGetCommission: '5'
					},
					{
						rType: 'youtubo',
						rTime: '2020-02-02',
						rCurrentState: '已完成',
						rGetCommission: '5'
					},
					{
						rType: 'youtubo',
						rTime: '2020-02-02',
						rCurrentState: '已完成',
						rGetCommission: '5'
					},
					{
						rType: 'youtubo',
						rTime: '2020-02-02',
						rCurrentState: '已完成',
						rGetCommission: '5'
					},
					{
						rType: 'youtubo',
						rTime: '2020-02-02',
						rCurrentState: '已完成',
						rGetCommission: '5'
					},
					{
						rType: 'youtubo',
						rTime: '2020-02-02',
						rCurrentState: '已完成',
						rGetCommission: '5'
					},
					{
						rType: 'youtubo',
						rTime: '2020-02-02',
						rCurrentState: '已完成',
						rGetCommission: '5'
					},
					{
						rType: 'youtubo',
						rTime: '2020-02-02',
						rCurrentState: '已完成',
						rGetCommission: '5'
					},
					{
						rType: 'youtubo',
						rTime: '2020-02-02',
						rCurrentState: '已完成',
						rGetCommission: '5'
					}
				],
			};
		},
		created() {
			this.getListData('init')
		},
		methods: {
			onLoad() {
				this.getListData('load')
				console.log(33)
			},
			getListData(type) {
				console.log(11)
				this.isLoad = true
				this.isRefresh = false
				if (type == 'load') {
					this.pageNo += 1
				} else {
					this.pageNo = 1
					this.isFinished = false
				}
				this.$Model.GetTaskList({
					group_id: this.taskType,
					task_level: this.taskGrade,
					page_no: this.pageNo,
					is_u: 0
				}, data => {
					this.$nextTick(() => {
						this.isLoad = false
					})
					if (data.code == 1) {
						if (type == 'load') {
							if (this.pageNo == 1) {
								this.listData = data.info
							} else {
								this.listData = this.listData.concat(data.info)
							}
						} else {
							this.listData = data.info
						}
						if (this.pageNo == data.data_total_page) {
							this.isFinished = true
						} else {
							this.isFinished = false
						}
					} else {
						this.listData = ''
						this.isFinished = true
					}
				})
			},
			onRefresh() {
				this.getListData('init')
			},
			receiveTask(id, item) {
				if (!localStorage['Token']) {
					this.$router.push('/login')
				} else {
					this.$Model.ReceiveTask(id, data => {
						if (data.code == 1) {
							item.is_l = 1
							this.pageNo = 0
						} else {
							this.$toast(data.code_dec)
							// this.popUpWindows=true
						}
					})
				}
			},
		}
	};
</script>

<style>
	.PageBox>>>.mytable {
		background-color: #FFFFFF;
		width: 91%;
		margin: 0 auto;
		margin-top: 26px;
	}

	.PageBox>>>table {
		width: 100%;
		border: none;
		/* border-spacing: 1px; */
		border-collapse: collapse;
	}

	.PageBox>>>th:nth-child(1) {
		background-color: #b3b5fc;
		font-weight: 600;
		font-size: 13px;
		/* padding: 6px 0px; */
		border: none;
		height: 33px;
		width: 26%;
	}

	.PageBox>>>th:nth-child(2) {
		background-color: #b3b5fc;
		font-weight: 600;
		font-size: 13px;
		/* padding: 6px 0px; */
		border: none;
		height: 33px;
		width: 24%px;
	}

	.PageBox>>>th:nth-child(3) {
		background-color: #b3b5fc;
		font-weight: 600;
		font-size: 13px;
		/* padding: 6px 0px; */
		border: none;
		height: 33px;
		width: 24%;
	}

	.PageBox>>>th:nth-child(4) {
		background-color: #b3b5fc;
		font-weight: 600;
		font-size: 13px;
		/* padding: 6px 0px; */
		border: none;
		height: 33px;
		width: 26%;
	}

	.PageBox>>>th {
		border-right: 1px solid #FFFFFF !important;
	}

	.PageBox>>>table tr td {
		font-size: 11px;
		color: #626262;
		padding: 8px 0px;
		text-align: center;
		border: none;
		border-right: 1px solid #FFFFFF !important;
	}

	table tbody tr:nth-child(odd) {
		background: #eff0f2;
		height: 40px;
	}

	table tbody tr:nth-child(even) {
		background: #e1e1f9;
	}

	.van-tabs>>>.van-tabs__nav--line {
		padding-bottom: 6px;
	}

	.van-list>>>.TaskItem {
		align-items: center !important;
		color: #000;
		padding-top: 20px;
	}

	.van-list>>>.TaskItem .icon {
		margin-top: 0px;
		min-width: 60px;
	}

	.van-list>>>.TaskItem .van-button--disabled {
		background: #888 !important;
		border-color: #888 !important;
	}

	.van-pull-refresh {
		min-height: calc(100vh - 46px);
	}

	.popUpWindows {
		width: 50%;
		min-width: 300px;
		max-width: 400px;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 4;
		border-radius: 4px;
	}

	.popUpWindows .title {
		text-align: center;
		color: rgb(128, 128, 255);
		font-size: 18px;
		line-height: 60px;
	}

	.popUpWindows .content {
		width: 100%;
		padding: 15px 20px 30px 20px;
		color: #000;
		text-align: center;
	}

	.popUpWindows .btn {
		display: flex;
		padding: 30px 20% 30px 20%;
		justify-content: space-between;
		color: #fff;
	}

	.popUpWindows .btn>p {
		line-height: 30px;
		text-align: center;
		background-color: rgb(211, 211, 211);
		width: 44%;
		border-radius: 4px;
	}

	.popUpWindows .btn>p:nth-child(2) {
		background-color: rgb(128, 128, 255);
	}

	.masking {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: rgba(90, 90, 90, 0.6);
		z-index: 3;
	}
</style>
